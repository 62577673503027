let url = '';
if (window.location.host === 'brij.ticketak.com') {
    url = "http://18.136.206.103:3800";
} else if (window.location.host === "dev.brij.tech" || window.location.host === "dev-pos-dual-display.brij.tech") {
    url = "http://shringar.brij.tech:3501";
    // url = "http://18.136.206.103:3810"
} else if (window.location.host === "gogo-test.brij.tech") {
    url = "http://18.136.206.103:3801";
} else if (window.location.host === "uat.brij.tech") {
    url = "http://18.136.206.103:3811";
} else if (window.location.host === "solapur.brij.tech") {
    // url = "http://18.138.243.76:3800";
    url = "https://solapur-api.brij.tech";
} else if (window.location.host === "dashboard-basra.brij.tech") {
    url = "https://api-basra.brij.tech:3901";
} else if (window.location.host === "dashboard-basra.brij.tech") {
    url = "https://api-basra.brij.tech:3901";
} else if (window.location.hostname === "192.168.1.189") {
    url = "http://192.168.1.189:3800";
}else if(window.location.hostname==="xanadu-pos-dual-display.brij.tech"){
    url = "https://xanadu-api.brij.tech";

} else {
    // Testing
    // url = "http://18.136.206.103:3901";
    // url = "http://shringar.brij.tech:3501";

    url = "http://localhost:3800";  


    // local IPS
    // url = "http://192.168.1.156:3800";
    // url = "http://192.168.1.181:3800";
    // url = "http://159.65.251.47:3900";
    // url = "http://157.230.36.254:3800";

    // Below For Brij Solapur
    // url = "http://18.136.206.103:3800";

    // UatBrijTech
    // url = "http://18.136.206.103:3811";

    // DevBrijTech
    // url = "http://18.136.206.103:3810";

    // Below For GOGO
    // url = "http://18.136.206.103:3801";
}
export const BASEURL = url;
export const IMGBASEURL = url;
